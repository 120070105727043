export const ArrowLeft = ({ className }: { className?: string }) => (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.307554 9.50891L9.50896 0.307513C9.91902 -0.102504 10.583 -0.102504 10.9931 0.307513C11.4031 0.7172 11.4031 1.38195 10.9931 1.79163L2.53369 10.251L10.993 18.7103C11.403 19.12 11.403 19.7847 10.993 20.1944C10.788 20.3995 10.5196 20.502 10.251 20.502C9.98229 20.502 9.71356 20.3995 9.50891 20.1944L0.307507 10.993C-0.102512 10.5833 -0.10251 9.9186 0.307554 9.50891Z"
        fill="white"
      />
      <path
        d="M9.00696 9.50891L18.2084 0.307513C18.6181 -0.102504 19.2828 -0.102504 19.6925 0.307513C20.1025 0.7172 20.1025 1.38195 19.6925 1.79163L11.2335 10.251L19.6924 18.7103C20.1025 19.12 20.1025 19.7847 19.6924 20.1944C19.4878 20.3995 19.2191 20.502 18.9504 20.502C18.6817 20.502 18.413 20.3995 18.2083 20.1944L9.00692 10.993C8.59695 10.5833 8.59695 9.9186 9.00696 9.50891Z"
        fill="white"
      />
    </svg>
  )
  
  export const ArrowRight = ({ className }: { className?: string }) => (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.211 9.5089L10.8896 0.307513C10.4742 -0.102504 9.80151 -0.102504 9.38609 0.307513C8.97073 0.717199 8.97073 1.38195 9.38609 1.79163L17.9558 10.251L9.38614 18.7103C8.97078 19.12 8.97078 19.7847 9.38614 20.1944C9.59385 20.3994 9.8657 20.502 10.1379 20.502C10.4101 20.502 10.6823 20.3994 10.8896 20.1944L20.211 10.993C20.6264 10.5833 20.6264 9.91859 20.211 9.5089Z"
        fill="white"
      />
      <path
        d="M11.3981 9.5089L2.07672 0.307513C1.66169 -0.102504 0.988271 -0.102504 0.573242 0.307513C0.157878 0.717199 0.157878 1.38195 0.573242 1.79163L9.14256 10.251L0.57329 18.7103C0.157926 19.12 0.157926 19.7847 0.57329 20.1944C0.780613 20.3994 1.05284 20.502 1.32503 20.502C1.59721 20.502 1.86944 20.3994 2.07677 20.1944L11.3982 10.993C11.8135 10.5833 11.8135 9.91859 11.3981 9.5089Z"
        fill="white"
      />
    </svg>
  )